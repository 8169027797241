import '../css/app.css'
import Alpine from 'alpinejs'
import Splide from '@splidejs/splide'
import baguetteBox from 'baguettebox.js'
import AOS from 'aos'
import './cookie3.js'

AOS.init({
  once: true,
  duration: 500,
  easing: 'ease-in-out',
})

Alpine.start()

// Splide
document.addEventListener('DOMContentLoaded', function () {
  baguetteBox.run('.baguette-slider', {
    noScrollbars: true,
    async: true,
  })

  const galleries = document.getElementsByClassName('splide-gallery')
  for (let i = 0; i < galleries.length; i++) {
    new Splide(galleries[i], {
      lazyLoad: 'nearby',
      perPage: 5,
      gap: 0,
      pagination: false,
      breakpoints: {
        1280: {
          perPage: 4,
        },
        1024: {
          perPage: 3,
        },
        640: {
          perPage: 2,
        },
      },
    }).mount()
  }

  const galleriesFull = document.getElementsByClassName('splide-gallery-full')
  for (let i = 0; i < galleriesFull.length; i++) {
    new Splide(galleriesFull[i], {
      lazyLoad: 'nearby',
      perPage: 6,
      gap: 0,
      pagination: false,
      breakpoints: {
        1440: {
          perPage: 5,
        },
        1280: {
          perPage: 4,
        },
        1024: {
          perPage: 3,
        },
        640: {
          perPage: 2,
        },
      },
    }).mount()
  }

  const cards = document.getElementsByClassName('splide-cards')
  for (let i = 0; i < cards.length; i++) {
    new Splide(cards[i], {
      lazyLoad: 'nearby',
      perPage: 3,
      gap: 20,
      pagination: false,
      breakpoints: {
        640: {
          perPage: 2,
        },
        480: {
          perPage: 1,
        },
      },
    }).mount()
  }
})





