import * as CookieConsent from "vanilla-cookieconsent";
import '@orestbida/iframemanager/dist/iframemanager.js';

const im = iframemanager();

im.run({
  onChange: ({ changedServices, eventSource }) => {
    if (eventSource.type === 'click') {
      const servicesToAccept = [
        ...CookieConsent.getUserPreferences().acceptedServices['videos'],
        ...changedServices,
      ];

      CookieConsent.acceptService(servicesToAccept, 'videos');
    }
  },

  currLang: 'de',

  services: {
    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',

      iframe: {
        allow:
          'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },

      languages: {
        en: {
          notice:
            'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> of youtube.com.',
          loadBtn: 'Load once',
          loadAllBtn: "Don't ask again",
        },
        de: {
          notice: 'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: "Nicht nochmal fragen",
        }
      },
    },

    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      iframe: {
        allow: 'fullscreen; picture-in-picture;',
      },

      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`;
        const response = await (await fetch(url)).json();
        const thumbnailUrl = response[0]?.thumbnail_large;
        thumbnailUrl && setThumbnail(thumbnailUrl);
      },

      languages: {
        en: {
          notice:
            'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">terms and conditions</a> of vimeo.com.',
          loadBtn: 'Load once',
          loadAllBtn: "Don't ask again",
        },
        de: {
          notice:
            'Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: "Nicht nochmal fragen",
        },
      },
    },
  },
});

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },

    videos: {
      services: {
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => im.acceptService('youtube'),
          onReject: () => im.rejectService('youtube'),
        },
        vimeo: {
          label: 'Vimeo Embed',
          onAccept: () => im.acceptService('vimeo'),
          onReject: () => im.rejectService('vimeo'),
        },
      },
    },

    ads: {},
  },

  language: {
    default: 'de',
    translations: {
      de: {
        "consentModal": {
          "label": "Cookie-Einwilligung",
          "title": "Hallo Reisender, es ist Zeit für Cookies!",
          "description": "Um unsere Website optimal zu gestalten, fortlaufend zu verbessern und Ihnen interessengerechte Inhalte anzuzeigen, verwenden wir Cookies.",
          "acceptAllBtn": "Alle akzeptieren",
          "closeIconLabel": "Alle ablehnen und schließen",
          "acceptNecessaryBtn": "Alle ablehnen",
          "showPreferencesBtn": "Einstellungen verwalten",
          "footer": "<a href=\"/datenschutz\">Datenschutzerklärung</a>"
        },
        "preferencesModal": {
          "title": "Zentrum für Einwilligungseinstellungen",
          "acceptAllBtn": "Alle akzeptieren",
          "acceptNecessaryBtn": "Alle ablehnen",
          "savePreferencesBtn": "Einstellungen speichern",
          "closeIconLabel": "Modal schließen",
          "serviceCounterLabel": "Service|Dienste",
          "sections": [
            {
              "title": "Hat jemand ... Cookies gesagt?",
              "description": "Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere <a href=\"/datenschutz\">Datenschutzerklärung</a>."
            },
            {
              "title": "Technisch notwendige Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
              "description": "Notwendige Cookies die gebraucht werden um die Webseite zu benutzen.",
              "linkedCategory": "necessary"
            },
            {
              "title": "Videos",
              "description": "Youtube/Vimeo verwenden Cookies, um die Video-Wiedergabe und Interaktion mit dem Video-Player zu ermöglichen und erfasst dabei auch personenbezogene Daten wie IP-Adressen.",
              "linkedCategory": "videos",
              "cookieTable": {
                "headers": {
                  "name": "Cookie",
                  "Service": "Service",
                  "description": "Beschreibung"
                }
              }
            }
          ]
        }
      },
      en: {
        "consentModal": {
          "label": "Cookie Consent",
          "title": "Hello traveller, it's cookie time!",
          "description": "Our website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent.",
          "acceptAllBtn": "Accept all",
          "closeIconLabel": "Reject all and close",
          "acceptNecessaryBtn": "Reject all",
          "showPreferencesBtn": "Manage preferences",
          "footer": "<a href=\"#link\">Privacy Policy</a><a href=\"#link\">Terms and conditions</a>"
        },
        "preferencesModal": {
          "title": "Consent preferences center",
          "acceptAllBtn": "Accept all",
          "acceptNecessaryBtn": "Reject all",
          "savePreferencesBtn": "Save preferences",
          "closeIconLabel": "Close modal",
          "serviceCounterLabel": "Service|Services",
          "sections": [
            {
              "title": "Somebody said ... cookies?",
              "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            },
            {
              "title": "Strictly necessary cookies <span class=\"pm__badge\">Always enabled</span>",
              "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              "linkedCategory": "necessary"
            },
            {
              "title": "Analytics cookies",
              "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
              "linkedCategory": "analytics",
              "cookieTable": {
                "headers": {
                  "name": "Cookie",
                  "Service": "Service",
                  "description": "Description"
                },
              }
            },
          ]
        }
      },
    },
  },
});

window.showCookieSettings = function () {
  CookieConsent.showPreferences();
}